<template>
  <div class="mt-2">
    <vue-skeleton-loader
      :width="400"
      :height="15"
    />
    <vue-skeleton-loader
      class="mt-2"
      :width="80"
      :height="12"
    />
    <vue-skeleton-loader
      class="mt-2"
      :width="200"
      :height="15"
    />
    <vue-skeleton-loader
      class="mt-2"
      :width="140"
      :height="12"
    />
    <vue-skeleton-loader
      class="mt-2"
      :width="110"
      :height="15"
    />
    <vue-skeleton-loader
      class="mt-2"
      :width="300"
      :height="15"
    />
    <vue-skeleton-loader
      class="mt-2"
      :width="142"
      :height="12"
    />
    <vue-skeleton-loader
      class="mt-2"
      :width="174"
      :height="15"
    />
    <vue-skeleton-loader
      class="mt-2"
      :width="190"
      :height="15"
    />
    <vue-skeleton-loader
      class="mt-2"
      :width="150"
      :height="12"
    />
    <vue-skeleton-loader
      class="mt-2"
      :width="255"
      :height="15"
    />
  </div>
</template>

<script>
import VueSkeletonLoader from 'skeleton-loader-vue'

export default {
  components: {
    VueSkeletonLoader,
  },

}
</script>

<style>

</style>
